<template>
  <section class="w3l-pricing py-5">
        <div class="container py-md-5 py-4">
            <div class="title-heading-w3 text-center mb-sm-5 mb-4">
                <h5 class="title-small mb-1">Get Started</h5>
                <h3 class="title-style">The Best Pricing Plans</h3>
            </div>
            <div class="row t-in justify-content-center">
                <div class="col-lg-4 col-md-6 price-main-info">
                    <div class="price-inner card box-shadow">

                        <div class="card-body">
                            <h4 class="text-uppercase text-center mb-3">Basic Plan</h4>
                            <h5 class="card-title pricing-card-title">
                                Free
                            </h5>
                            <ul class="list-unstyled mt-3 mb-4">
                                <li v-for="obj in offers" :key="obj.i" :class="obj.plan==='free'?'not':'disable'"> <span class="fa fa-check"></span> {{obj.title}}</li>
                                <!-- <li> <span class="fa fa-check"></span> Web Development</li>
                                <li > <span class="fa fa-check"></span> Logo design</li>
                                <li class="disable"> <span class="fa fa-check"></span> SEO optimization</li>
                                <li class="disable"> <span class="fa fa-check"></span> Wordpress integration</li> -->
                            </ul>
                            <div class="read-more mt-4 pt-lg-2 text-center">
                                <router-link to="/contact" class="btn btn-style"> Order Now</router-link>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 price-main-info">
                    <div class="price-inner card box-shadow active">

                        <div class="card-body">
                            <label class="price-label">Recommended</label>
                            <h4 class="text-uppercase text-center mb-3">Pro Plan</h4>
                            <h5 class="card-title pricing-card-title">
                                 <span class="align-top">$</span>2<span class="align-bottom">/hr.</span>

                            </h5>
                            <ul class="list-unstyled mt-3 mb-4">
                                <li v-for="obj in offers" :key="obj.i" :class="obj.plan==='free'?' ':obj.plan==='pro'?' ':'disable'"> <span class="fa fa-check"></span> {{obj.title}}</li>
                            </ul>
                            <div class="read-more mt-4 pt-lg-2 text-center">
                                <router-link to="/contact" class="btn btn-style"> Order Now</router-link>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 price-main-info">
                    <div class="price-inner card box-shadow">

                        <div class="card-body">
                            <h4 class="text-uppercase text-center mb-3">Premium Plan</h4>
                            <h5 class="card-title pricing-card-title">
                                <span class="align-top">$</span>5<span class="align-bottom">/hr.</span>
                            </h5>
                            <ul class="list-unstyled mt-3 mb-4">
                                <li v-for="obj in offers" :key="obj.i"> <span class="fa fa-check"></span> {{obj.title}}</li>
                            </ul>
                            <div class="read-more mt-4 pt-lg-2 text-center">
                                <router-link to="/contact" class="btn btn-style"> Order Now</router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
  name: 'PriceSection',
  props: {
    show: Boolean
  },
  data () {
      return {
        offers:[
            {title:'UI/UX Design', plan:'free'},
            {title:'Web Development', plan:'free'},
            {title:'Logo design', plan:'pro'},
            {title:'SEO optimization', plan:''},
            {title:'Wordpress integration', plan:''},
        ]
        
      }
    },
    created () {
    
    },
    methods:{
       
        
    },
  
}
</script>

