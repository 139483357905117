<template>
  <div>
    <section v-show="show" class="w3l-bottom-grids-6 pt-sm-5 pb-5" id="features">
        <div class="container pt-lg-4">
            <div class="grids-area-hny main-cont-wthree-fea row">
                <div class="col-md-3 col-sm-4 ps-xl-5">
                    <h4 class="ab-exper-count mb-sm-4 ps-lg-4">07</h4>
                    <p class="ab-content ps-lg-4">years of working experience </p>
                </div>
                <div class="col-xl-8 col-md-9 col-sm-8 offset-xl-1 ps-xl-0 pe-xl-5 mt-sm-0 mt-4">
                    <h3 class="title-style mb-sm-5 mb-4">I'm a designer & developer with a passion for web design</h3>
                    <div class="row">
                        <div class="col-lg-4 col-md-6 grids-feature">
                            <div class="area-box active">
                                <div class="icon-style">
                                    <i class="fas fa-lightbulb"></i>
                                </div>
                                <h4><a href="#feature" class="title-head">Creative Design</a></h4>
                                <router-link :to="{name:'About'}" class="btn more p-0">Explore More<i
                                        class="fas fa-long-arrow-alt-right ms-1"></i></router-link>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 grids-feature mt-md-0 mt-4">
                            <div class="area-box">
                                <div class="icon-style">
                                    <i class="fas fa-laptop-code"></i>
                                </div>
                                <h4><a href="#feature" class="title-head">Web Design</a></h4>
                                <router-link :to="{name:'About'}" class="btn more p-0">Explore More<i
                                        class="fas fa-long-arrow-alt-right ms-1"></i></router-link>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 grids-feature mt-lg-0 mt-4">
                            <div class="area-box">
                                <div class="icon-style">
                                    <i class="fas fa-layer-group"></i>
                                </div>
                                <h4><a href="#feature" class="title-head">Brand Identity</a></h4>
                                <router-link :to="{name:'About'}" class="btn more p-0">Explore More<i
                                        class="fas fa-long-arrow-alt-right ms-1"></i></router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="w3l-aboutblock1 pt-lg-5 pt-2 pb-5" id="about">
        <div class="container py-md-5 py-4">
            <div class="row align-items-center">
                <div class="col-lg-4">
                    <div class="position-relative">
                        <img src="../assets/images/about.jpg" alt="" class="radius-image img-fluid">
                    </div>
                </div>
                <div class="col-lg-8 ps-lg-5 mt-lg-0 mt-5">
                    <h5 class="title-small mb-1">My Intro</h5>
                    <h3 class="title-style">About Me</h3>
                    <p class="mt-3" style="text-align:justify">Hi! I am a web developer & designer focused on crafting great web experiences. Coding and Designing have been my passion since the days I started working with computers but I found myself into web development/design since 2014. I enjoy creating beautifully designed, intuitive and functional websites.</p>
                    <p class="mt-3" style="text-align:justify">For over past 7 years, I have worked for some of the best digital agencies and wonderful clients to create some award winning works. And, I can make this happen for your business as well.</p>
                    <div class="my-info mt-md-5 mt-4">
                        <ul class="single-info">
                            <li class="name-style">Name</li>
                            <li>:</li>
                            <li>
                                <p>Mudassir Husain</p>
                            </li>
                        </ul>
                        
                        <ul class="single-info">
                            <li class="name-style">From</li>
                            <li>:</li>
                            <li>
                                <p>Gorakhpur, India</p>
                            </li>
                        </ul>
                        <ul class="single-info">
                            <li class="name-style">Email</li>
                            <li>:</li>
                            <li>
                                <p><a href="mailto:mudassirprnc@gmail.com">mudassirprnc@gmail.com</a></p>
                            </li>
                        </ul>
                    </div>
                    <a :href="pdfLink" download='Mudassir-Husain' class="btn btn-style mt-5">Download CV</a>
                </div>
            </div>
        </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'AboutSection',
  props: {
    show: Boolean
  },
  data () {
      return {
        pdfLink: require("../assets/images/resume.jpg"),
        
      }
    },
    created () {
    
    },
    methods:{
       
        
    },
  
}
</script>

