<template>
  <div>
    <inner-banner title="Services" />
    <section class="w3l-bottom-grids-6 py-5" id="features">
        <div class="container py-md-5 py-4">
            <div class="title-heading-w3 text-center mb-sm-5 mb-4">
                <h5 class="title-small mb-1">specialized In</h5>
                <h3 class="title-style">What I Offer</h3>
            </div>
            <div class="row">
                <div class="col-lg-3 col-md-6 grids-feature">
                    <div class="area-box active">
                        <div class="icon-style">
                            <i class="fas fa-lightbulb"></i>
                        </div>
                        <h4><a href="#feature" class="title-head">Creative Design</a></h4>
                        <router-link to="/about" class="btn more p-0">Explore More<i class="fas fa-long-arrow-alt-right ms-1"></i></router-link>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 grids-feature mt-md-0 mt-4">
                    <div class="area-box">
                        <div class="icon-style">
                            <i class="fas fa-laptop-code"></i>
                        </div>
                        <h4><a href="#feature" class="title-head">Web Design</a></h4>
                        <router-link to="/about" class="btn more p-0">Explore More<i class="fas fa-long-arrow-alt-right ms-1"></i></router-link>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 grids-feature mt-lg-0 mt-4">
                    <div class="area-box">
                        <div class="icon-style">
                            <i class="fas fa-layer-group"></i>
                        </div>
                        <h4><a href="#feature" class="title-head">Brand Identity</a></h4>
                        <router-link to="/about" class="btn more p-0">Explore More<i class="fas fa-long-arrow-alt-right ms-1"></i></router-link>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 grids-feature mt-lg-0 mt-4">
                    <div class="area-box">
                        <div class="icon-style">
                            <i class="fas fa-chart-pie"></i>
                        </div>
                        <h4><a href="#feature" class="title-head">Graphic Design</a></h4>
                        <router-link to="/about" class="btn more p-0">Explore More<i class="fas fa-long-arrow-alt-right ms-1"></i></router-link>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <service-section />
    <price-section />
    <Footer />
  </div>
</template>

<script>
import InnerBanner from '../components/InnerBanner.vue'
import ServiceSection from '../components/ServiceSection.vue'
import Footer from '../components/Footer.vue'
import PriceSection from '../components/PriceSection.vue'

export default {
  name: 'Services',
  components: {
    InnerBanner,
    ServiceSection,
    Footer,
    PriceSection
  }
}
</script>
