<template>
  <header id="site-header" class="fixed-top">
        <div class="container">
            <nav class="navbar navbar-expand-lg navbar-light">
                
                <router-link to="/" class="navbar-brand">
                   <img height="50px" src="../assets/images/logo.png" alt="">
                </router-link>
                <button class="navbar-toggler collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#navbarScroll" aria-controls="navbarScroll" aria-expanded="false"
                    aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon fa icon-expand fa-bars"></span>
                    <span class="navbar-toggler-icon fa icon-close fa-times"></span>
                </button>
                <div class="collapse navbar-collapse" id="navbarScroll">
                    <ul class="navbar-nav mx-auto my-2 my-lg-0 navbar-nav-scroll">
                        <li class="nav-item" v-for="obj in menu" :key="obj.index">
                            <router-link :to="{name:obj.to}" @click.native="toTop()" class="nav-link" >{{obj.title}}</router-link>
                        </li>
                        
                        <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" href="#" id="navbarScrollingDropdown" role="button"
                                data-bs-toggle="dropdown" aria-expanded="false">
                                More <i class="fas fa-angle-down"></i>
                            </a>
                            <ul class="dropdown-menu" aria-labelledby="navbarScrollingDropdown">
                                <li><a class="dropdown-item" href="blog.html">Blog Posts</a></li>
                                <li><a class="dropdown-item" href="blog-single.html">Blog Single</a></li>
                                <!-- <li><a class="dropdown-item" href="error.html">404 Page</a></li> -->
                                <!-- <li><a class="dropdown-item" href="email-template.html">Email Template</a></li> -->
                                <!-- <li><a class="dropdown-item" href="shortcodes.html">Shortcodes</a></li> -->
                                <!-- <li><a class="dropdown-item" href="landing-single.html">Landing Page</a></li> -->
                            </ul>
                        </li>
                        
                    </ul>
                    <form action="error.html" method="GET" class="d-flex search-header">
                        <input class="form-control" type="search" placeholder="Enter Keyword..." aria-label="Search"
                            required>
                        <button class="btn btn-style" type="submit">Search</button>
                    </form>
                </div>
                <!-- toggle switch for light and dark theme -->
                <div class="cont-ser-position">
                    <nav class="navigation">
                        <div class="theme-switch-wrapper">
                            <label class="theme-switch" for="checkbox">
                                <input @click="toggletheme()" v-model="themeBox" type="checkbox" id="checkbox">
                                <div class="mode-container">
                                    <i class="gg-sun"></i>
                                    <i class="gg-moon"></i>
                                </div>
                            </label>
                        </div>
                    </nav>
                </div>
                <!-- //toggle switch for light and dark theme -->
            </nav>
        </div>
    </header>
</template>

<script>
import $ from 'jquery'
export default {
  name: 'NavBar',
  data () {
      return {
        themeBox : false,
        menu:[
            {title:'Home', to:'Home'},
            {title:'My Intro', to:'About'},
            {title:'Services', to:'Services'},
            {title:'Contact', to:'Contact'}
        ],
      }
    },
    created () {
        window.addEventListener('scroll', this.handleScroll);
        const currentTheme = localStorage.getItem('theme');
        if (currentTheme) {
            document.documentElement.setAttribute('data-theme', currentTheme);
        
            if (currentTheme === 'dark') {
                this.themeBox = true;
            }
        }else{
            localStorage.setItem('theme', 'light');   
        }
    },
    destroyed() {
        window.removeEventListener('scroll', this.handleScroll);
    },
    methods:{
        toggletheme() {
            const toggleSwitch = document.querySelector('.theme-switch input[type="checkbox"]');
            const currentTheme = localStorage.getItem('theme');
            if (currentTheme) {
                document.documentElement.setAttribute('data-theme', currentTheme);
            
                if(toggleSwitch.checked){
                    document.documentElement.setAttribute('data-theme', 'dark'); 
                    localStorage.setItem('theme', 'dark');   
                }else{
                    document.documentElement.setAttribute('data-theme', 'light');
                    localStorage.setItem('theme', 'light'); 
                }
            }

            
        },
        handleScroll() {
            this.scrollpx = window.scrollY;
            if (this.scrollpx >= 80) {
                $("#site-header").addClass("nav-fixed");
            } else {
                $("#site-header").removeClass("nav-fixed");
            }
        },
        toTop() {
            window.scrollTo({top: 0, behavior: 'smooth'});
        },
       
       
        
    },
  
}
</script>

<style scoped>
.router-link-exact-active{
 color: #f54748 !important;
}
</style>
