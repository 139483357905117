<template>
    <section class="w3l-progress py-5" id="progress">
        <div class="container py-md-5 py-4">
            <div class="title-heading-w3 text-center mb-sm-5 mb-4">
                <h5 class="title-small mb-1">My Skills</h5>
                <h3 class="title-style">My Expertise Area</h3>
            </div>
            <div class="row py-lg-4">
                <div class="col-lg-6 pe-lg-5">
                    <div v-for="obj in skills.slice(0,5)" :key="obj.i" class="progress-info" :class="obj.position">
                        <h6 class="progress-tittle">{{obj.title}} <span class="">{{obj.perc}}</span></h6>
                        <div class="progress">
                            <div class="progress-bar progress-bar-striped " :class="obj.gradient" role="progressbar"
                                :style="{width: obj.perc}" aria-valuenow="95" aria-valuemin="0" aria-valuemax="100">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 ps-lg-5 mt-lg-0 mt-5">
                    <div v-for="obj in skills.slice(5,10)" :key="obj.i" class="progress-info" :class="obj.position">
                        <h6 class="progress-tittle">{{obj.title}} <span class="">{{obj.perc}}</span></h6>
                        <div class="progress">
                            <div class="progress-bar progress-bar-striped " :class="obj.gradient" role="progressbar"
                                :style="{width: obj.perc}" aria-valuenow="95" aria-valuemin="0" aria-valuemax="100">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'SkillSection',
    data: () => ({
        skills:[
            {title:'Web Design', perc:'80%', gradient:'gradient-1', position:'info1'},
            {title:'HTML/CSS', perc:'95%', gradient:'gradient-2', position:'info2'},
            {title:'Javascript', perc:'60%', gradient:'gradient-3', position:'info3'},
            {title:'Vue JS', perc:'85%', gradient:'gradient-4', position:'info4'},
            {title:'Bootstrap', perc:'85%', gradient:'gradient-4', position:'info4'},
            {title:'Graphic Design', perc:'95%', gradient:'gradient-2', position:'info2'},
            {title:'UI/UX Design', perc:'85%', gradient:'gradient-4', position:'info4'},
            {title:'PHP', perc:'95%', gradient:'gradient-2', position:'info2'},
            {title:'Laravel', perc:'85%', gradient:'gradient-4', position:'info4'},
            {title:'Python', perc:'60%', gradient:'gradient-3', position:'info3'},
        ],
        
    }),
    computed:{
         
      
    },
    created () {
        
    },
    
    
    methods: {
                  

    },
  
  
}
</script>

<style scoped>

</style>
