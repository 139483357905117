<template>
    <div>
        <footer class="footer-w3ls text-center py-5">
        <div class="container pt-4">
            <div class="mx-auto" style="max-width:600px;">
                <a href="index.html" class="footer-logo py-1">
                    <img height="50px" src="../assets/images/logo.png" alt="">
                </a>
                <p class="mt-4 text-white">Feel free to drop me a line! I would get back to you within 24 hours.</p>
                <div class="social-icons-main mt-4 pb-3">
                    <ul class="social-icons3">
                        <li v-for="obj in social" :key="obj.index" v-show="obj.href">
                            <a :href="obj.href" target="_blank">
                                <i :class="obj.icon"></i>
                            </a>
                        </li>
                        
                    </ul>
                </div>
            </div>
            <!-- copyright -->
            <p class="copy-right-w3 text-white mt-5 pt-4">© {{ new Date().getFullYear() }} <a class="footer-link" href="https://yoututor.in">Yoututor</a>. All rights reserved.
            </p>
        </div>
    </footer>
    <button @click="toTop()" id="movetop" title="Go to top">
        <span class="fas fa-level-up-alt" aria-hidden="true"></span>
    </button>
    </div>
</template>

<script>
export default {
    name: 'Footer',
    
    data: () => ({
        social:[
            {title:'Twitter', icon:'fab fa-twitter', href:'https://twitter.com/Mudassir_tech'},
            {title:'Facebook', icon:'fab fa-facebook-f', href:'https://www.facebook.com/mhmasti'},
            {title:'Youtube', icon:'fab fa-youtube', href:'https://www.youtube.com/c/YouTutor786'},
            {title:'dribbble', icon:'fab fa-dribbble', href:''},
            {title:'instagram', icon:'fab fa-instagram', href:''},
        ],  
        
    }),
    computed:{
      
    },
    created () {
        window.addEventListener('scroll', this.handleScroll);
        
    },
    destroyed() {
        window.removeEventListener('scroll', this.handleScroll);
    },
    
    
    methods: {
        handleScroll() {
            this.scrollpx = window.scrollY;
            if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
                document.getElementById("movetop").style.display = "block";
            } else {
                document.getElementById("movetop").style.display = "none";
            }
        },
        toTop() {
            window.scrollTo({top: 0, behavior: 'smooth'});
        },


    },
  
  
}
</script>

<style scoped>
.footer-link{
    text-decoration: none !important;
}
</style>
