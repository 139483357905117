<template>
    <section class="w3l-timeline-1 py-5">
        <div class="container py-lg-5 py-4">
            <div class="title-heading-w3 text-center mb-sm-5 mb-4">
                <h5 class="title-small">Resume</h5>
                <h3 class="title-style">Awesome Journey</h3>
            </div>
            <div class="row">
                <div class="col-lg-6">
                    <h5 class="sub-title-timeline"><i class="fas fa-briefcase"></i> Experience</h5>
                    <div class="timeline">
                        <div v-for="obj in experiences" :key="obj.i" class="column">
                            <div class="title">
                                <h2>{{obj.title}}</h2>
                            </div>
                            <div class="description">
                                <p>{{obj.company}}</p>
                                <h6><i class="fas fa-calendar-alt"></i> {{obj.year}}</h6>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 mt-lg-0 mt-4">
                    <h5 class="sub-title-timeline"><i class="fas fa-briefcase"></i> Freelancer</h5>
                    <div class="timeline">
                        <div v-for="obj in freelancers" :key="obj.i" class="column">
                            <div class="title">
                                <h2>{{obj.title}}</h2>
                            </div>
                            <div class="description">
                                <p>{{obj.company}}</p>
                                <h6><i class="fas fa-calendar-alt"></i> {{obj.year}}</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'ResumeSection',
    data: () => ({
        experiences:[
            {title:'Sr. Software Developer', company:'Force Digital Learning', year:'2020 - Current'},
            {title:'Co-Founder', company:'GoLearn & YouTutor', year:'2018'},
            {title:'Web Developer', company:'Innovinc Educational Services', year:'2017 - 2020'},
        ],
        freelancers:[
            {title:'Full Stack Developer', company:'Capital Study Circle', year:'2021 - Current'},
            {title:'Full Stack Developer', company:'Epaper - Press Media English Daily', year:'2021'},
            {title:'Full Stack Developer', company:'News Portal - India Khabar', year:'2018'},
        ],
    }),
    computed:{
         
      
    },
    created () {
        
    },
    
    
    methods: {
                  

    },
  
  
}
</script>

<style scoped>

</style>
