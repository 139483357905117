<template>
    <section class="w3l-gallery pb-5" id="gallery">
        <div class="container py-md-5 py-4">
            <div class="title-heading-w3 text-center mb-sm-5 mb-4">
                <h5 class="title-small mb-1">Portfolio</h5>
                <h3 class="title-style">Some of my most recent projects</h3>
            </div>
            <div class="row">
                <div v-for="(obj, i) in images" :key="i" class="col-lg-4 col-md-6 item" :class="obj.space">
                    <a href="javascript:void(0)" @click="openGallery(i)" data-lightbox="example-set" data-title="Project 2"
                        class="zoom d-block">
                        <img  class="card-img-bottom d-block" :src="obj.src" alt="Card image cap">
                        <span class="overlay__hover"></span>
                        <span class="hover-content">
                            <span class="title">{{obj.title}}</span>
                            <span class="content">{{obj.content}}</span>
                        </span>
                    </a>
                </div>
            </div>
        </div>
        <light-box ref="lightbox"
        :media="media"
        :show-caption="true"
        :show-light-box="false"
        :site-loading="siteLoading"></light-box>
    </section>
</template>

<script>
import LightBox from 'vue-image-lightbox'
import siteLoading from '../assets/images/siteloading.gif'
export default {
    name: 'PortfolioSection',
    components: {
        LightBox,
    },
    data: () => ({
        siteLoading,
        images:[
            {title:'Project 1', content:'Capital Study Circle', src:require('@/assets/images/g1.jpg'), space:'' },
            {title:'Project 2', content:'ItStreet Private Limited', src:require('@/assets/images/g2.jpg'), space:'mt-4 mt-md-0' },
            {title:'Project 3', content:'India Khabar - Online News Portal', src:require('@/assets/images/g3.jpg'), space:'mt-4 mt-lg-0' },
            {title:'Project 4', content:'Bharat Samachar Pvt. Ltd.', src:require('@/assets/images/g7.jpg'), space:'mt-4 pt-lg-2' },
            {title:'Project 5', content:'YouTutor - Online Education', src:require('@/assets/images/g8.jpg'), space:'mt-4 pt-lg-2' },
            {title:'Project 6', content:'Indian Journalist Association', src:require('@/assets/images/g9.jpg'), space:'mt-4 pt-lg-2' },
            {title:'Project 6', content:'Dashboard 1', src:require('@/assets/images/g10.jpg'), space:'mt-4 pt-lg-2' },
            {title:'Project 6', content:'Dashboard 2', src:require('@/assets/images/g11.jpg'), space:'mt-4 pt-lg-2' },
            {title:'Project 6', content:'Dashboard 3', src:require('@/assets/images/g12.jpg'), space:'mt-4 pt-lg-2' },
            
        ],
        media:[
          { 
            thumb: require('@/assets/images/g1.jpg'),
            src: require('@/assets/images/g1.jpg'),
            caption: '<h4 style="padding:10px; margin-bottom:10px;">Capital Study Circle</h4>',
          },
          { 
            thumb: require('@/assets/images/g2.jpg'),
            src: require('@/assets/images/g2.jpg'),
            caption: '<h4 style="padding:10px; margin-bottom:10px;">ItStreet Private Limited</h4>',
          },
          { 
            thumb: require('@/assets/images/g3.jpg'),
            src: require('@/assets/images/g3.jpg'),
            caption: '<h4 style="padding:10px; margin-bottom:10px;">India Khabar - Online News Portal</h4>',
          },
          { 
            thumb: require('@/assets/images/g7.jpg'),
            src: require('@/assets/images/g7.jpg'),
            caption: '<h4 style="padding:10px; margin-bottom:10px;">Bharat Samachar Pvt. Ltd.</h4>',
          },
          { 
            thumb: require('@/assets/images/g8.jpg'),
            src: require('@/assets/images/g8.jpg'),
            caption: '<h4 style="padding:10px; margin-bottom:10px;">YouTutor - Online Education</h4>',
          },
          { 
            thumb: require('@/assets/images/g9.jpg'),
            src: require('@/assets/images/g9.jpg'),
            caption: '<h4 style="padding:10px; margin-bottom:10px;">Indian Journalist Association</h4>',
          },
          { 
            thumb: require('@/assets/images/g10.jpg'),
            src: require('@/assets/images/g10.jpg'),
            caption: '<h4 style="padding:10px; margin-bottom:10px;">Dashboard 1</h4>',
          },
          { 
            thumb: require('@/assets/images/g11.jpg'),
            src: require('@/assets/images/g11.jpg'),
            caption: '<h4 style="padding:10px; margin-bottom:10px;">Dashboard 2</h4>',
          },
          { 
            thumb: require('@/assets/images/g12.jpg'),
            src: require('@/assets/images/g12.jpg'),
            caption: '<h4 style="padding:10px; margin-bottom:10px;">Dashboard 3</h4>',
          },
          
        ]
        
    }),
    computed:{
         
      
    },
    created () {
        
    },
    
    
    methods: {
        openGallery(index) {
            this.$refs.lightbox.showImage(index)
        }          

    },
  
  
}
</script>

<style scoped>
.vue-lb-info{
  height: 0px !important;
}
</style>
