import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Services from '../views/Services.vue'
import Contact from '../views/Contact.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    meta:{label:'Home'},
    component: Home
  },
  {
    path: '/services',
    name: 'Services',
    meta:{label:'My Services'},
    component: Services
  },
  {
    path: '/contact',
    name: 'Contact',
    meta:{label:'Contact'},
    component: Contact
  },
  {
    path: '/about',
    name: 'About',
    meta:{label:'My Info'},
    component: () => import('../views/About.vue')
  },
  {
    path: "*",
    name: "Missing",
    component: () => 
      import("../views/404.vue"),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
