<template>
    <section class="w3l-testimonials py-5" id="testimonials">
        <div class="container py-md-5 py-4">
            <div class="row">
                <div class="col-md-10 mx-auto">
                    <carousel :per-page="1" :loop="true" :speed="1000" :autoplay="true"  :autoplayTimeout="4000" :paginationEnabled="false" :navigationEnabled="false" >
                        <slide v-for="obj in views" :key="obj.i">
                        <div class="item">
                            <div class="slider-info mt-lg-4 mt-3">
                                <div class="message pl-5">
                                    <img src="../assets/images/quote.png" alt="" class="img-fluid mb-2" />
                                    <p><q v-html="obj.content"></q></p>
                                    <div class="name mt-4 mb-4">
                                        <h4>{{obj.name}}</h4>
                                        <p>{{obj.post}}</p>
                                    </div>
                                </div>
                                <div class="img-circle">
                                    <img :src="obj.src" class="img-fluid radius-image" :alt="obj.name">
                                </div>
                            </div>
                        </div>
                        </slide>
                    </carousel>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'TestimonialSection',
    data: () => ({
        views:[
            {name:'Kusum Singhal', post:'iTStreet', content:"I've outsourced several projects to Mr. Mudassir Husain. I can honestly say that he is one of the best developers we’ve worked with.", src:require('@/assets/images/c5.png') },
            {name:'Sumit Upadhyay', post:'', content:'For me, Mudassir Husain means a man in computer himself who completely understands all my professional requirements and needs in my own language. Seeing his capita and performance I recommend him to my friends and organizations for the best web design and software solutions.', src:require('@/assets/images/c3.jpg') },
            
        ],
    }),
    computed:{
         
      
    },
    created () {
        
    },
    
    
    methods: {
                  

    },
  
  
}
</script>

<style scoped>

</style>
