import Vue from 'vue'
import App from './App.vue'
import router from './router'
import $ from 'jquery';
window.$ = window.jQuery = $;
import 'popper.js';

import './assets/css/style-liberty.css';
import './assets/js/bootstrap.min.js';
require('vue-image-lightbox/dist/vue-image-lightbox.min.css')


import VueLazyLoad from 'vue-lazyload'
Vue.use(VueLazyLoad)

import VueCarousel from 'vue-carousel';
Vue.use(VueCarousel);

// Progress Bar
import VueProgressBar from 'vue-progressbar'
Vue.use(VueProgressBar, {
    color: 'rgb(143, 255, 199)',
    failedColor: 'red',
    thickness: '5px',
    transition: {
        speed: '0.2s',
        opacity: '0.6s',
        termination: 300
      },
    autoRevert: true,
})

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
