<template>
  <div class="">
    <inner-banner title="Contact Me" />
    <contact-section />
    <Footer />
  </div>
</template>

<script>
import InnerBanner from '../components/InnerBanner.vue'
import ContactSection from '../components/ContactSection.vue'
import Footer from '../components/Footer.vue'

export default {
  name: 'About',
  components: {
    Footer,
    InnerBanner,
    ContactSection
  }
}
</script>
