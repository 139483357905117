<template>
  <div class="home">
    <banner />
    <about-section :show="true" />
    <resume-section />
    <skill-section />
    <portfolio-section />
    <service-section />
    <testimonial-section />
    <Footer />
  </div>
</template>

<script>
import Banner from '../components/Banner.vue'
import AboutSection from '../components/AboutSection.vue'
import ResumeSection from '../components/ResumeSection.vue'
import SkillSection from '../components/SkillSection.vue'
import PortfolioSection from '../components/PortfolioSection.vue'
import ServiceSection from '../components/ServiceSection.vue'
import TestimonialSection from '../components/TestimonialSection.vue'
import Footer from '../components/Footer.vue'

export default {
  name: 'Home',
  components: {
    Banner,
    AboutSection,
    ResumeSection,
    SkillSection,
    PortfolioSection,
    ServiceSection,
    TestimonialSection,
    Footer
  }
}
</script>
