<template>
    <section class="inner-banner py-5">
        <div class="w3l-breadcrumb py-lg-5">
            <div class="container pt-4 pb-sm-4">
                <h4 class="inner-text-title font-weight-bold pt-sm-5 pt-4">{{title}}</h4>
                <ul class="breadcrumbs-custom-path">
                    <li><router-link to="/">Home</router-link></li>
                    <li v-for="obj in items" :key="obj.i"><i class="fas fa-angle-right mx-2"></i>
                        <router-link :to="obj.path">{{obj.meta.label}}</router-link>
                    </li>
                </ul>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'InnerBanner',
    props: {
        title: String
    },
    data: () => ({
        items:[],
    }),
    watch:{
        $route(){
            this.getRoute();
        }     
    },
    created () {
        this.getRoute();
    },
    methods: {
        getRoute() {
            this.items = this.$route.matched;
            console.log(this.$route);
        },           

    },
  
  
}
</script>

<style scoped>

</style>
