<template>
    <section class="w3l-contact py-5" id="contact">
        <div class="container py-lg-5 py-4">
            <div class="title-heading-w3 text-center mb-sm-5 mb-4">
                <h5 class="title-small">Get In Touch</h5>
                <h3 class="title-style">Contact Me</h3>
            </div>
            <div class="row contact-block">
                <div class="col-md-6 contact-left pe-lg-5">
                    <h3 class="mb-sm-4 mb-3">Contact Info</h3>
                    <p class="cont-para mb-sm-5 mb-4">I enjoy discussing new projects and design challenges. Please share as much info, as possible so we can get the most out of our first catch-up.</p>
                    <div class="cont-details">
                        <p><i class="fas fa-map-marker-alt"></i>Gorakhpur, UP, India.</p>
                        <p><i class="fas fa-phone-alt"></i><a href="tel:+1(21) 234 4567">+19 9648 2097 95</a></p>
                        <p><i class="fas fa-envelope-open-text"></i><a href="mailto:mudassirprnc@gmail.com"
                                class="mail">mudassirprnc@gmail.com</a></p>
                    </div>
                    <h4 class="mb-3 mt-5">Follow Me</h4>
                    <ul class="social-icons-contact">
                        <li v-for="obj in social" :key="obj.index" v-show="obj.href">
                            <a :href="obj.href" target="_blank">
                                <i :class="obj.icon"></i>
                            </a>
                        </li>
                    </ul>
                </div>
                <div class="col-md-6 contact-right mt-md-0 mt-5 ps-lg-0">
                        <div class="input-grids">
                            <input v-model="form.name" type="text" name="name" class="form-control contact-input" placeholder="Your Name*">
                            <div v-if="form.errors.has('name')" v-html="form.errors.get('name')" />

                            <input v-model="form.email" type="email" name="email" class="form-control contact-input" placeholder="Your Email*">
                            <div v-if="form.errors.has('email')" v-html="form.errors.get('email')" />

                            <input v-model="form.subject" type="text" name="subject" class="form-control contact-input" placeholder="Your subject*">
                            <div v-if="form.errors.has('subject')" v-html="form.errors.get('subject')" />

                        </div>
                        <div class="form-input">
                            <textarea v-model="form.msg" name="w3lMessage" class="form-control contact-input" placeholder="Type your message here*"
                                required=""></textarea>
                            <div v-if="form.errors.has('msg')" v-html="form.errors.get('msg')" />
                        </div>
                        <button @click="submitMsg" class="btn btn-style">Send Message</button>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import Form from 'vform'
export default {
    name: 'ContactSection',
    data: () => ({
        loader:true,
        msgSend:false,
        rules: [v => v.length >= 150 || 'Atleast 150 characters'],
        social:[
            {title:'Twitter', icon:'fab fa-twitter', href:'https://twitter.com/Mudassir_tech'},
            {title:'Facebook', icon:'fab fa-facebook-f', href:'https://www.facebook.com/mhmasti'},
            {title:'Youtube', icon:'fab fa-youtube', href:'https://www.youtube.com/c/YouTutor786'},
            {title:'dribbble', icon:'fab fa-dribbble', href:''},
            {title:'instagram', icon:'fab fa-instagram', href:''},
        ],
        form: new Form({
            id:'',
            name:'',
            email:'',
            msg:'',
        }),     
    }),
    created () {
        
    },
    
    methods: {
        submitMsg(){
            this.form.post('contact')
            .then((result)=>{
                this.$Progress.start()
                if(result.status==200){
                    this.msgSend=true
                }
                    
            })
            .catch(()=>{
                this.$Progress.fail()
            }) 
        },             

    },
  
  
}
</script>

<style scoped>

</style>
