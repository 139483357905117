<template>
<div>
  <section class="w3l-banner py-5" id="home">
        <div class="container py-md-5 py-4">
            <div class="row align-items-center pt-4">
                <div class="col-md-6 banner-left pe-xl-5">
                    <h4>Hi, I'm Mudassir Husain</h4>
                    <h3 class="mb-3 mt-1"><span class="typed-text">Developer</span><span class="cursor"> </span></h3>
                    <p class="banner-sub me-md-5">I love to work as developer and designer.
                        I always try my best to make good UI with the best UX.
                    </p>
                    <div class="d-flex align-items-center buttons-banner mt-sm-5 mt-4">
                        <router-link :to="{name:'About'}" class="btn btn-style me-2">Hire Me</router-link>
                    </div>
                </div>
                <div class="col-md-6">
                </div>
            </div>
        </div>
        <!-- animations icons -->
        <div class="icon-effects-w3-1 text-right">
            <img src="../assets/images/icon2.png" alt="" class="img-fluid">
        </div>
        <div class="icon-effects-w3-2 text-right">
            <img src="../assets/images/icon3.png" alt="" class="img-fluid">
        </div>
        <div class="icon-effects-w3-3 text-right">
            <img src="../assets/images/icon1.png" alt="" class="img-fluid">
        </div>
        <div class="icon-effects-w3-4 text-right">
            <img src="../assets/images/icon6.png" alt="" class="img-fluid">
        </div>
        <!-- //animations icons -->
    </section>
    <section class="w3l-clients pt-5 pb-4" id="clients">
        <div class="container">
            <div class="company-logos text-center">
                <div class="row mx-auto justify-content-center">
                    <div v-for="obj in logos" :key="obj.index" class="col-md-2 col-sm-2 col-4 ">
                        <img style="max-height:80px" :src="obj.img" :alt="obj.title" class="img-fluid">
                    </div>
                    
                </div>
            </div>
        </div>
    </section>
    </div>
</template>

<script>

export default {
    name: 'Banner',
    data: () => ({
        logos:[
            {title:'PayPal', img:require('@/assets/images/logo1.png')},
            {title:'Google', img:require('@/assets/images/logo2.png')},
            {title:'Laravel', img:require('@/assets/images/logo3.png')},
            {title:'Vue Js', img:require('@/assets/images/logo4.png')},
            {title:'Bootstrap', img:require('@/assets/images/logo5.png')},
            {title:'Bootstrap', img:require('@/assets/images/logo6.png')},
        ],
    }),
    computed:{
         
      
    },
    
    
    methods: {
                   

    },
  
  
}
</script>

<style scoped>

</style>
