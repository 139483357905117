<template>
  <div id="app">
    <div id="nav">
      <nav-bar v-if="!$route.path.includes('.html')" />
    </div>
    <router-view/>
  </div>
</template>

<script>
import NavBar from './components/NavBar.vue'

export default {
  name: 'Home',
  components: {
    NavBar
  }
}
</script>